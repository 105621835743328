import { navigate } from 'gatsby';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import backgroundVideo from '../../static/404-background.mp4';
import backgroundImage from '../../static/404-image.jpg';
import Button from '../components/button-like/button';
import Nav from '../components/nav';
import Seo from '../components/seo';

import './404.css';

const seo = {
    metaTitle      : 'Страница не найдена',
    metaDescription: 'Страница не найдена'
};

const cssBlock = 'page-404';

const NotFoundPage: React.FC = () => {
    const [hideVideo, setHideVideo] = useState<boolean >(false);
    const videoRef = useRef<HTMLVideoElement>(null);

    const navPanel = {
        hierarchicalLinks: [],
        joinButtonText   : '',
        switchLangUrl    : 'https://autotech.team/en'
    };

    const playVideo = useCallback(() => {
        if(!videoRef || !videoRef.current) {
            return;
        }

        if(!videoRef.current.paused || videoRef.current.currentTime > 0 && !videoRef.current.ended) {
            return;
        }

        void videoRef.current.play();
    }, [videoRef, videoRef.current]);

    useEffect(() => {
        document.body.addEventListener('click', playVideo);
        document.body.addEventListener('touchstart', playVideo);

        return () => {
            document.body.removeEventListener('click', playVideo);
            document.body.removeEventListener('touchstart', playVideo);
        };
    }, []);

    const toggleHideVideo = (value: boolean) => {
        setHideVideo(value);
    };

    useEffect(() => {
        if(!videoRef || !videoRef.current) {
            return;
        }

        const videoElement = videoRef.current;

        const isPaused: boolean = videoRef.current.paused;

        videoElement.addEventListener('play', () => toggleHideVideo(false));
        videoElement.addEventListener('pause', () => toggleHideVideo(true));

        toggleHideVideo(isPaused);

        return () => {
            videoElement.removeEventListener('play', () => toggleHideVideo(false));
            videoElement.removeEventListener('pause', () => toggleHideVideo(true));
        };
    }, [
        videoRef, videoRef.current
    ]);

    return (
        <div className={`${cssBlock}`}>
            <Nav hasContactButton={false} hasProgressBar={false} data={navPanel} />
            <Seo seo={seo} />
            <div className={`${cssBlock}__wrapper`}>

                <div className={`${cssBlock}__gradient`} />

                <video
                    ref={videoRef}
                    style={{ opacity: hideVideo ? 0 : 1 }}
                    playsInline={true} autoPlay={true} muted={true} loop={true} src={backgroundVideo}
                    className={`${cssBlock}__video`}
                />

                {hideVideo && <img src={backgroundImage} className={`${cssBlock}__image`} alt="background" />}

                <div className={`${cssBlock}__container`}>
                    <h1 className={`${cssBlock}__title`}>Страница не найдена</h1>
                    <div className={`${cssBlock}__button-wrapper`}>
                        <Button className={`${cssBlock}__button`} onClick={() => navigate('/')}>
                            Вернуться на главную
                        </Button>
                    </div>
                </div>
            </div>
        </div>);
};

export default NotFoundPage;

